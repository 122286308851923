
export const dateFormatter = (isoDate?: string) => {
    if(!isoDate) {
        return
    }
    const date = new Date(isoDate);

    if (isNaN(date.getTime())) {
        return; 
    }



   return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true 
});;
} 