import "./switch-toggle.scss";

interface SwitchToggleProps<T extends string = string> {
  elements: {
    type: T;
    name: string;
  }[];
  activeElement: T;
  onChange: (value: T) => void;
}

const SwitchToggle = <T extends string>({
  elements,
  activeElement,
  onChange,
}: SwitchToggleProps<T>) => {
  const isActive = activeElement === elements[1].type;

  return (
    <div className="switch-toggle-container">
      <span
        className={`label ${!isActive ? 'active-label' : ''}`}
        onClick={() => onChange(elements[0].type)}
      >
        {elements[0].name}
      </span>

      <div
        className={`switch-toggle ${isActive ? 'active' : ''}`}
        onClick={() => onChange(isActive ? elements[0].type : elements[1].type)}
      >
        <div className={`toggle-button ${isActive ? 'active' : ''}`} />
      </div>

      <span
        className={`label ${isActive ? 'active-label' : ''}`}
        onClick={() => onChange(elements[1].type)}
      >
        {elements[1].name}
      </span>
    </div>
  );
};

export default SwitchToggle;

