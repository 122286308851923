import React from 'react';
import { useTable } from 'react-table';
import './table-content.scss';

interface TableContentProps {
  columns: any[];
  data: any[];
}

const TableContent: React.FC<TableContentProps> = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <div className="table-container">
      <table {...getTableProps()} className="custom-table">
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...headerGroupProps}>
              {headerGroup.headers.map((column) => {
                const { key: columnKey, ...columnProps } = column.getHeaderProps();
                
                const classNames = (column as any).className || ''; 

                return (
                  <th key={columnKey} {...columnProps} className={classNames}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { key: rowKey, ...rowProps } = row.getRowProps();
            return (
              <tr key={rowKey} {...rowProps}>
                {row.cells.map((cell) => {
                  const { key: cellKey, ...cellProps } = cell.getCellProps();
                  return (
                    <td key={cellKey} {...cellProps}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableContent;
