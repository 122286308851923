import './statistical-info-card.scss';
import { ReactComponent as PositiveIcon } from '../assets/icons/positive.svg';
import { ReactComponent as NegativeIcon } from '../assets/icons/negative.svg';

interface StatisticalInfoCardProps {
    title: string;
    value: string;
    performanceToday: string;
    performanceChange?: string;
    hideHelper?: boolean;
}

const StatisticalInfoCard: React.FC<StatisticalInfoCardProps> = ({
    title,
    value,
    performanceToday,
    performanceChange,
    hideHelper
}) => {
    return (
        <div className="statistical-info-card flex-row flex-gap-200">
            <div className='flat-values-container flex-row flex-gap-100'>
                <span className='title'>{title}</span>
                <div className="value-container flex-column flex-gap-10">
                    <span className="total">${value}</span>

                    {performanceToday && (
                        <div className='flex-row flex-gap-5'>
                            <PositiveIcon />
                            <p className="change">{performanceToday} <span className='helper'>Performance Today</span></p>
                        </div>
                    )}
                </div>
            </div>
            <div className="percentage-details-container  flex-row flex-align-end-end">
                <div className='details flex-column flex-gap-10 '>
                    <span className='percentage'>{performanceChange}</span>
                    {!hideHelper && <div className='flex-row flex-gap-5'>
                        <PositiveIcon />
                        <span className="change">change over the last 24h</span>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default StatisticalInfoCard;
