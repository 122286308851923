import { configureStore } from '@reduxjs/toolkit';
import { userReducer, UserState } from './user.slice';
import { loadingReducer, LoadingState } from './loading.slice';
// import { navigationReducer, NavigationState } from './navigation.slice';

export interface AppStore {
  user: UserState;
  loading: LoadingState
}

export const store = configureStore({
  reducer: {
    user: userReducer,
    loading: loadingReducer
    // navigation: navigationReducer,
  }
});
