import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from '../models';

export interface UserState {
  user?: UserModel;
}

const initialState: UserState = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state: UserState, action: PayloadAction<UserModel>) => {
      state.user = action.payload;
    },
    removeUser: (state: UserState) => {
      state.user = undefined;
    },
  }
});

export const userReducer = userSlice.reducer;
export const userSliceActions = {
  saveUser: userSlice.actions.saveUser,
  removeUser: userSlice.actions.removeUser,
};