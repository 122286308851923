import { RouteObject } from "react-router-dom";
import ProviderListPage from "./provider-list/provider-list-page";
import ProviderDetailsPage from "./provider-details/provider-details-page";
import AddProviderPage from "./add-provider/add-provider-page";

    const providerManagementRouter: RouteObject[] = [
        {
            path: '',
            element: <ProviderListPage/>,
                
        },
        {
            path: 'add-provider',
            element: <AddProviderPage/>
        },
        {
            path: 'provider-details/:id',
            element: <ProviderDetailsPage/>
        },
]

export default providerManagementRouter;