import React from 'react';
import './asset-card.scss';
import { WalletInfoDetails } from '../models';
import { ReactComponent as BitcoinIcon } from '../assets/icons/bitcoin-wallet.svg';
import { ReactComponent as EthereumIcon } from '../assets/icons/ethereum-wallet.svg';
import { ReactComponent as USDTIcon } from '../assets/icons/usdt-wallet.svg';

interface AssetCardProps extends WalletInfoDetails {}

const AssetCard: React.FC<AssetCardProps> = ({ asset, walletAddress, walletAlias, balance, status }) => {

  const renderIcon = () => {
    switch (asset) {
      case 'BTC':
        return <BitcoinIcon />;
      case 'ETH':
        return <EthereumIcon />;
      case 'USDT':
        return <USDTIcon />;
      default:
        return null;
    }
  };

  const statusClass =  status?.toLowerCase();
  
  return (
    <div className='asset-card flex-row'>
      <div className='flex-column flex-gap-20 flex-1'>
        <div className='wallet-info flex-row flex-gap-10'>
          <span className='bold'>{walletAlias}</span>
          <span className='bold hide-sm'>{' | '}</span>
          <span className='wallet-address'>{walletAddress}</span>
        </div>
        <span className='balance'>{balance} {asset}</span>
        <span className={`status ${statusClass}`}>{status}</span> 
      </div>
      <div className='flex-row flex-align-end-center flex-1'>
        {renderIcon()}
      </div>
    </div>
  );
};

export default AssetCard;
