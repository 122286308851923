import DoughnutChart from "../../../../components/doughnut-chart";
import StatisticalInfoCard from "../../../../components/statistical-info-card";
import { ReactComponent as PositiveIcon } from '../../../../assets/icons/positive.svg';
import { ReactComponent as RecommendedIcon } from '../../../../assets/icons/recommeded.svg';
import TableContent from "../../../../components/table-content";
import { ReactComponent as BtcIcon } from '../../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthIcon } from '../../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as UsdtIcon } from '../../../../assets/icons/usdt-rounded.svg';
import './liquidity-management.scss';


const columns = [
    {
      Header: 'Active Assets',
      columns: [
        {
          Header: '',
          accessor: 'name', 
          Cell: ({ cell: { value } } : { cell: any }) => {
            switch (value) {
              case 'BTC':
                return <BtcIcon />;
              case 'ETH':
                return <EthIcon />;
              case 'USDT':
                return <UsdtIcon />;
              default:
                return null;
            }
          },
        },
        {
          Header: '',
          accessor: 'value',
        },
        {
          Header: '',
          accessor: 'change',
          Cell: ({ cell: { value } } : { cell: any }) => {
            return (
                <div className="flex-row flex-gap-10 flex-align-start-center">
                    <PositiveIcon/>
                    {value}
                </div>
            )
          },
        },
      ],
    },
    {
      Header: 'Top pairs',
      columns: [
        {
          Header: 'Markets',
          accessor: 'markets', 
          className: 'secondary-header'
        },
        {
          Header: 'Base Price',
          accessor: 'basePrice', 
          className: 'secondary-header'
        },
        {
          Header: '24h Trade Volume',
          accessor: 'volume', 
          className: 'secondary-header'
        },
        {
          Header: 'Recommended',
          accessor: 'recommended', 
          className: 'secondary-header',
          Cell: ({ cell: { value } } : { cell: any }) => (
            
            value ? <RecommendedIcon/> : null
          ), 
        },
      ],
    },
  ];

  const data = [
    {
      name: 'BTC',
      value: '12 BTC',
      change: '80.00%',
      markets: 'Binance',
      basePrice: '$56,839.21',
      volume: '$2.28 billion',
      recommended: true,
    },
    {
      name: 'USDT',
      value: '34,356.89 USDT',
      change: '15.00%',
      markets: 'MEXC Global',
      basePrice: '$56,839.21',
      volume: '$2.28 billion',
      recommended: true,
    },
    {
      name: 'ETH',
      value: '789,897.56 ETH',
      change: '5.00%',
      markets: 'Bybit',
      basePrice: '$56,839.21',
      volume: '$2.28 billion',
      recommended: true,
    },
  ];
  

const LiquidityManagementPage = () => {
    return (
        <div className="liquidity-management-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Liquidity Management</span>
            </div>
            <div className="page-content limit-height">
                <StatisticalInfoCard
                    title="Total Liquidity"
                    value="140.000.000,689"
                    performanceToday="+ 200.000"
                    performanceChange="Available on the platform"
                    hideHelper={true}
                />
            </div>

            <div className="page-content flex-column flex-gap-20 limit-height">
                <div className="table-header">
                    <span className="table-title">Assets</span>
                </div>
                <div className="liquidity-container flex-row flex-gap-100 flex-align-start-center">
                    <div className="doughnut-chart-container">
                        <DoughnutChart theme="assets" />
                    </div>
                    <div className="liquidity-info flex-column flex-gap-30">
                        <div className="liquidity-item flex-row flex-gap-40 flex-align-center-center">
                            <span className="percentage">$ 256.098,982</span>
                            <div className="helper flex-row flex-gap-10"><PositiveIcon />change over the last 24h</div>
                        </div>
                        <div className="liquidity-item flex-row flex-gap-40 flex-align-center-center">
                            <span className="percentage">$ 789.731,115</span>
                            <div className="helper flex-row flex-gap-10"><PositiveIcon />change over the last 24h</div>
                        </div>
                        <div className="liquidity-item flex-row flex-gap-40 flex-align-center-center">
                            <span className="percentage">$ 553.972,145</span>
                            <div className="helper flex-row flex-gap-10"><PositiveIcon /> change over the last 24h</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content flex-column flex-gap-20">
            <div className="table-header">
                    <span className="table-title">Current liquidity status</span>
                </div>
            <TableContent columns={columns} data={data || []} />
            </div>
        </div>
    )
}

export default LiquidityManagementPage;