import { useState } from 'react';
import { ReactComponent as CopyIcon } from '../assets/icons/copy.svg';
import './copy-text.scss';

interface CopyTextProps {
    value: string;
}

const CopyText: React.FC<CopyTextProps> = ({ value }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(value).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); 
        });
    };

    return (
        <div className="copy-text-container" onClick={handleCopy}>
            <CopyIcon className={`${isCopied && 'copied'}`}/>
        </div>
    );
};

export default CopyText;