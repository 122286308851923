import { useLocation } from 'react-router-dom';
import { ReactComponent as BitcoinRoundedIcon } from '../../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthereumRoundedIcon } from '../../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as USDTRoundedIcon } from '../../../../assets/icons/usdt-rounded.svg';
import { ReactComponent as QuestionMark } from '../../../../assets/icons/question-mark-outline.svg';
import { ReactComponent as Check } from '../../../../assets/icons/check-input.svg';
import { ReactComponent as HourglassIcon } from '../../../../assets/icons/hourglass.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/clock.svg';
import { ReactComponent as LightningIcon } from '../../../../assets/icons/lightning.svg';
import { ReactComponent as LightbulbIcon } from '../../../../assets/icons/lightbulb.svg';
import './transactions-details-page.scss';
import { useForm } from 'react-hook-form';
import AppFormTextArea from '../../../../components/form/app-form-textarea';
import Button from '../../../../components/button';
import { apiService } from '../../../../services/api.service';
import { useEffect, useState } from 'react';
import { TransactionModel } from '../../../../models/transaction.model';
import { dateFormatter } from '../../../../utils/date-utils';

interface TransactionDetailsForm {
    description: string;
}

const TransactionsDetailsPage = () => {

    const [transaction, setTransaction] = useState<TransactionModel>();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const form = useForm<TransactionDetailsForm>({
        mode: 'onTouched',
        defaultValues: {}
    })
    const location = useLocation();
    const { transactionHash, asset } = location.state || {};
    const renderAssetIcon = () => {
        switch (asset) {
            case 'BTC':
                return <BitcoinRoundedIcon />;
            case 'ETH':
                return <EthereumRoundedIcon />;
            case 'USDT':
                return <USDTRoundedIcon />;
            default:
                return null;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    useEffect(() => {
        const getTransactionDetails = async () => {
          if (!transactionHash) {
            return
          }
          try {
          const response = await apiService.getTransactionByHash(transactionHash);
          
          return response.data;
          } catch (e) {
            return ;
          }
        }
    
        getTransactionDetails().then((transactionData) => setTransaction(transactionData))
    
      }, [transactionHash])

    return (
        <div className="transactions-details-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Transactions Details</span>
                <div className="wallet-info flex-row flex-align-center-center flex-gap-5">
                    {renderAssetIcon()}
                    <span className="address ellipsis">
                        {transactionHash}
                    </span>
                </div>
            </div>
            <div className="page-content">
                <div className='content-title'>
                    <span>Overview</span>
                </div>
                <div className='section flex-column flex-gap-40'>
                    <div className='item flex-row has-address'>
                        <div className='label flex-row flex-gap-10 flex-align-start-center'>
                            <QuestionMark />
                            <span>Transaction Hash:</span>
                        </div>
                        <div className='value flex-row flex-gap-10 flex-align-start-center'>
                            { isMobile && <QuestionMark className='hidden-icon'/>}
                            <span className='ellipsis'>{transaction?.txHash}</span>
                        </div>
                    </div>
                    <div className='item flex-row'>
                        <div className='label flex-row flex-gap-10 flex-align-start-center'>
                            <QuestionMark />
                            <span>Status:</span>
                        </div>
                        <div className='value flex-row flex-gap-10 flex-align-start-center'>
                            <span className={'status-chip active'}>
                                <Check />
                                {transaction?.status}
                            </span>
                        </div>
                    </div>
                    <div className='item flex-row'>
                        <div className='label flex-row flex-gap-10 flex-align-start-center'>
                            <QuestionMark />
                            <span>Block:</span>
                        </div>
                        <div className='value flex-row flex-gap-10 flex-align-start-center'>
                            <HourglassIcon />
                            <span className='accent'>{transaction?.blockNumber}</span>
                        </div>
                    </div>
                    <div className='item flex-row has-address'>
                        <div className='label flex-row flex-gap-10 flex-align-start-center'>
                            <QuestionMark />
                            <span>Timestamp:</span>
                        </div>
                        <div className='value flex-row flex-gap-10 flex-align-start-center'>
                            <ClockIcon />
                            <span>{dateFormatter(transaction?.date)}</span>
                        </div>
                    </div>
                    <div className='item flex-row'>
                        <div className='label flex-row flex-gap-10 flex-align-start-center'>
                            <QuestionMark />
                            <span>FIAT Worth:</span>
                        </div>
                        <div className='value flex-row flex-gap-10 flex-align-start-center'>
                            <span>Waiting</span>
                        </div>
                    </div>
                </div>
                <div className='transaction-action flex-row'>
                    <div className='label flex-row flex-gap-10 flex-align-start-center'>
                        <LightningIcon />
                        <span>Transaction action:</span>
                    </div>
                    <div className='value flex-row flex-gap-10 flex-align-start-center'>
                        <span>Transfer: </span>
                        <span>{transaction?.value}</span>
                        <span className='accent hide-sm'>USDT</span>
                        <span>To:</span>
                        <span>{transaction?.to}</span>
                    </div>
                </div>
                <div className='section flex-column flex-gap-40'>
                    <div className='item flex-row has-address'>
                        <div className='label flex-row flex-gap-10 flex-align-start-center'>
                            <QuestionMark />
                            <span>From:</span>
                        </div>
                        <div className='value flex-row flex-gap-10 flex-align-start-center'>
                            { isMobile && <QuestionMark className='hidden-icon'/>}
                            <span className='ellipsis'>{transaction?.from}</span>
                        </div>
                    </div>
                    <div className='item flex-row has-address'>
                        <div className='label flex-row flex-gap-10 flex-align-start-center'>
                            <QuestionMark />
                            <span>Interacted With (To):</span>
                        </div>
                        <div className='value flex-row flex-gap-10 flex-align-start-center'>
                        { isMobile && <QuestionMark className='hidden-icon'/>}
                            <span className='ellipsis'>{transaction?.to}</span>
                        </div>
                    </div>
                </div>
                <div className='section flex-column flex-gap-40'>
                    <div className='item flex-row'>
                        <div className='label flex-row flex-gap-10 flex-align-start-center'>
                            <QuestionMark />
                            <span>Value:</span>
                        </div>
                        <div className='value flex-row flex-gap-10 flex-align-start-center'>
                            <span>{transaction?.value}</span>
                        </div>
                    </div>
                    <div className='item flex-row'>
                        <div className='label flex-row flex-gap-10 flex-align-start-center'>
                            <QuestionMark />
                            <span>Transaction Fee:</span>
                        </div>
                        <div className='value flex-row flex-gap-10 flex-align-start-center'>
                            <span>{transaction?.gasUsed}</span>
                        </div>
                    </div>
                    <div className='item flex-row'>
                        <div className='label flex-row flex-gap-10 flex-align-start-center'>
                            <QuestionMark />
                            <span>Gas Price:</span>
                        </div>
                        <div className='value flex-row flex-gap-10 flex-align-start-center'>
                            <span>Waiting</span>
                        </div>
                    </div>
                </div>
                <div className='section flex-column flex-gap-40'>
                    <div className='item flex-row has-address'>
                        <div className='label flex-row flex-gap-10 flex-align-start-start'>
                            <QuestionMark />
                            <span>Add description</span>
                        </div>
                        <div className='value flex-column flex-gap-10 flex-align-start-end flex-1 '>
                            <AppFormTextArea
                                control={form.control}
                                name="description"
                                validations={{ maxLength: { value: 500, message: '500 Character Max' } }}
                                rows={6}
                            />
                            <Button
                                theme="accent"
                                label="Add"
                            ></Button>
                        </div>
                    </div>

                </div>
                <div className='helper-text-container flex-row flex-gap-10'>
                    <LightbulbIcon />
                    <div className='helper-text'>
                        A transaction is a cryptographically signed instruction that changes the blockchain state. Block explorers track the details of all transactions in the network.
                        Learn more about transactions in our <span className='accent'>Knowledge Base.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionsDetailsPage