import React from 'react';
import './modal.scss'; 
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg';
import Button from './button';

interface ModalProps {
    message: string;
    buttonText: string;
    onClose: () => void;
    onButtonClick?: () => void;
}

const Modal: React.FC<ModalProps> = ({ message, buttonText, onClose, onButtonClick }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <CheckIcon className="check-icon" /> 
                </div>
                <div className="modal-body flex-column flex-gap-15">
                    <span>Congratulations!</span>
                    <p>{message}</p>
                </div>
                <div className="modal-footer">
                    <Button theme="form" label={buttonText} onClick={onButtonClick} />
                </div>
            </div>
        </div>
    );
};

export default Modal;
