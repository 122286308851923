import { Outlet } from "react-router-dom";
import NavBar from "../../components/nav-bar";
import SideMenu from "../../components/side-menu";
import './app-layout.scss';
import { useState } from "react";


const AppLayout: React.FC = () => {
    const [ toggleMenu , setToggleMenu ] = useState(false);

    const handleToggleMenu = () => {
        setToggleMenu(!toggleMenu);
    }
    return (
        <div className="app-layout flex-column">
            <NavBar toggleMenu={handleToggleMenu}/>

            <div className="page-container flex-row">
            {<SideMenu isOpen={toggleMenu} toggleMenu={handleToggleMenu}/>}

                    <Outlet/>
            </div>
        </div>

    )
}

export default AppLayout;