import { RouteObject } from "react-router-dom";
import LoginPage from "./auth-page.login";

const authRouter: RouteObject[] = [
    {
        path: '',
        element: <LoginPage/>
    },
    {
        path: 'register'
    },
]

export default authRouter;