import React, { PropsWithChildren, useEffect } from 'react';
import { authService } from './services/auth.service';
import { userSliceActions } from './store/user.slice';
import { useDispatch } from 'react-redux';
import { apiService } from './services/api.service';

const AppInit: React.FC<PropsWithChildren> = ({children}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    apiService.attachDispatchToAxiosApi(dispatch);
    authService.getAuthenticatedUser()
      .then(async user => {
        if (user) {
          const response = await apiService.getUserDetails(user.userId)
          
          dispatch(userSliceActions.saveUser({
            ...response.data,
          }
        ))
 
        }
      });
  }, []);

  return <>{children}</>;
}

export default AppInit;