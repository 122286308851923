import React from 'react';
import './button.scss';
import { ForwardedProps } from '../types/component-props.types';

interface ButtonProps extends ForwardedProps {
  theme?: 'accent' | 'blue' | 'form' |  'form-outline' | 'filters-button' ;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const Button: React.FC<ButtonProps> = (
  {theme = 'accent', label, disabled, onClick, icon: Icon}
) => {
  return (
    <button
      className={`button ${theme}`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
       {Icon && <Icon className="button-icon" aria-hidden="true" />}
      <span>{label}</span>
    </button>
  );
};

export default Button;