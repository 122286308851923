import { RouteObject } from "react-router-dom";
import SupplierListPage from "./supplier-list/supplier-list-page";
import SupplierDetailsPage from "./supplier-details/supplier-details-page";
import AddSupplierPage from "./add-supplier/add-supplier-page";

    const supplierManagementRouter: RouteObject[] = [
        {
            path: '',
            element: <SupplierListPage/>,
                
        },
        {
            path: 'add-supplier',
            element: <AddSupplierPage/>
        },
        {
            path: 'supplier-details/:id',
            element: <SupplierDetailsPage/>
        },
]

export default supplierManagementRouter;