import './transaction-hub-page.scss';
import TableContent from '../../../components/table-content';
import Button from '../../../components/button';
import WalletCard from '../../../components/wallet-card';
import { ReactComponent as BitcoinIcon } from '../../../assets/icons/bitcoin-wallet.svg';
import { ReactComponent as USDTIcon } from '../../../assets/icons/usdt-wallet.svg';
import { ReactComponent as EthereumIcon } from '../../../assets/icons/ethereum-wallet.svg';
import { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AppFormInput from '../../../components/form/app-form-input';
import { WalletInfo, WalletType } from '../../../models';
import { ReactComponent as RemoveWalletIcon } from '../../../assets/icons/remove-wallet.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg';
import AppFormSelectInput from '../../../components/form/app-form-select-input';
import { apiService } from '../../../services/api.service';
import { LabelValue } from '../../../types/label-value.types';
import TableFilters from '../../../components/table-filters';
import CopyText from '../../../components/copy-text';
import TableExport from '../../../components/table-export';

const columns = [
  {
    Header: 'Transaction Hash',
    accessor: 'transactionHash',
    Cell: ({ value }: { value: string }) => (
      <div className="transaction-hash-cell">
        <div className="icon-wrapper"  role="button">
          <EyeIcon />
        </div>
        <span className="truncated-text">{value}</span>
        <CopyText value={value}/>
      </div>
    ),
  },
  {
    Header: 'Method',
    accessor: 'method',
    Cell: ({ value }: { value: string }) => (
      <span className={`${value.toLocaleLowerCase()}`}>{value}</span>
    ),
  },
  {
    Header: 'Block',
    accessor: 'block',
  },
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
  },
  {
    Header: 'From',
    accessor: 'from',
    Cell: ({ value, row }: { value: string, row: any }) => {
      return <div className='flex-row flex-gap-5 flex-align-center-center'>{
        row.original.fromAlias ? (<div className="from-to flex-column">
          <span>{row.original.fromAlias}</span>
          <span className='truncated-text secondary-text'>{value}</span>
        </div>) :
          (<div className="from-to flex-column">
            <span>{value}</span>
          </div>)
          }
          <CopyText value={value}/>
      </div>
    }
  },
  {
    Header: 'To',
    accessor: 'to',
    Cell: ({ value, row }: { value: string, row: any }) => {
      return <div className='flex-row flex-gap-5 flex-align-center-center'>{
        row.original.toAlias ? (<div className="from-to flex-column">
          <span>{row.original.toAlias}</span>
          <span className='truncated-text secondary-text'>{value}</span>
        </div>) :
          (<div className="from-to flex-column">
            <span>{value}</span>
          </div>)}
          <CopyText value={value}/>
          </div>
    }
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
];

const data = [
  {
    transactionHash: '0xE291cc3e5b9e0C9...',
    method: 'Received',
    block: '20627465',
    timestamp: 'October 2, 2024 at 12:00 AM',
    from: '0xE291cc3e5b9e0C9...',
    to: '0xE291cc3e5b9e0C9...',
    toAlias: 'Test 1',
    amount: '408.647707',
  },
  {
    transactionHash: '0xE291cc3e5b9e0C9...',
    method: 'Sent',
    block: '20627465',
    timestamp: 'October 4, 2024 at 11:45 AM',
    from: '0xE291cc3e5b9e0C9...',
    to: '0xE291cc3e5b9e0C9...',
    toAlias: 'Test',
    amount: '408.647707',
  },
  {
    transactionHash: '0xE291cc3e5b9e0C9...',
    method: 'Received',
    block: '20627465',
    timestamp: 'October 4, 2024 at 13:45 AM',
    from: '0xE291cc3e5b9e0C9...',
    to: '0xE291cc3e5b9e0C9...',
    amount: '408.647707',
  },
  {
    transactionHash: '0xE291cc3e5b9e0C9...',
    method: 'Sent',
    block: '20627465',
    timestamp: 'October 1, 2024 at 11:45 AM',
    from: '0xE291cc3e5b9e0C9...',
    to: '0xE291cc3e5b9e0C9...',
    amount: '408.647707',
  },
  {
    transactionHash: '0xE291cc3e5b9e0C9...',
    method: 'Received',
    block: '20627465',
    timestamp: 'October 8, 2024 at 11:45 AM',
    from: '0xE291cc3e5b9e0C9...',
    fromAlias: 'From Alias',
    to: '0xE291cc3e5b9e0C9...',
    amount: '408.647707',
  },
  {
    transactionHash: '0xE291cc3e5b9e0C9...',
    method: 'Sent',
    block: '20627465',
    timestamp: 'October 4, 2024 at 11:45 AM',
    from: '0xE291cc3e5b9e0C9...',
    to: '0xE291cc3e5b9e0C9...',
    amount: '408.647707',
  },
]

export interface WalletCard {
  walletType: string;
  total: number;
  percentageChange: number;
  description: string;
  icon: ReactNode;
}

const walletCardsInitial: WalletCard[] = [
  {
    walletType: 'BTC',
    total: 0,
    percentageChange: 8.5,
    description: 'Up from yesterday',
    icon: <BitcoinIcon />
  },
  {
    walletType: 'ETH',
    total: 0,
    percentageChange: 2.1,
    description: 'Up from yesterday',
    icon: <EthereumIcon />
  },
  {
    walletType: 'USDT',
    total: 0,
    percentageChange: -4.3,
    description: 'Down from yesterday',
    icon: <USDTIcon />
  },
];


interface SearchWalletAddressForm {
  walletAddress: string;
}

const TransactionHubPage = () => {
  const [ walletCards, setWalletCards ] = useState<WalletCard[]>(walletCardsInitial);
  const [selectedAsset, setSelectedAsset] = useState<WalletCard>();
  const [selectedWallets, setSelectedWallets] = useState<LabelValue[]>([]);
  const [wallets, setWallets] = useState<WalletInfo[]>([])
  const form = useForm<SearchWalletAddressForm>();
  const handleSelectAsset = (wallet: WalletCard) => {
    setSelectedAsset(wallet);
  }

  const labelValueOptions: LabelValue<string>[] = wallets?.map((wallet) => ({
    label: wallet.walletAddress,
    value: wallet.walletAlias,
  }));

  useEffect(() => {
    const getWalletsBalance = async (walletType: string) => {
      try {
        const response = await apiService.getBalanceByInternalCurrency(walletType);
        return response.data; 
      } catch (e) {
        console.error(`Error fetching balance for ${walletType}:`, e);
        return 0;
      }
    };

    const updateWalletBalances = async (wallets: WalletCard[]) => {
      const updatedWallets = await Promise.all(
        wallets.map(async (wallet) => {
          const balance = await getWalletsBalance(wallet.walletType);
          
          return { ...wallet, total: +balance ? +balance : 0 };
        })
      );
      setWalletCards(updatedWallets);
    };

    updateWalletBalances(walletCards);
  }, [])

  useEffect(() => {
    const getWallets = async () => {
      if (!selectedAsset)
        return
      const response = await apiService.getWalletByTypeAndAsset(WalletType.EXT, selectedAsset.walletType)
      return response.data
    }

    const getAssetBalance = async () => {
      if (!selectedAsset)
        return
      try {
      const response = await apiService.getBalanceByInternalCurrency(selectedAsset.walletType)
      return response.data
      } catch (e) {
        return 0;
      }
    }
  
    getAssetBalance().then((balance) => setSelectedAsset(prevValue => {
      if (!prevValue) return undefined; 
    
      return {
        ...prevValue,
        total: balance
      };
    }))
    getWallets().then((walletsData) => setWallets(walletsData))
  }, [selectedAsset?.walletType]);


  const handleAddWallet = () => {
    const selectedValue = form.getValues('walletAddress');
    const selectedOption = labelValueOptions.find(option => option.value === selectedValue);

    if (selectedOption && !selectedWallets.some(wallet => wallet.value === selectedOption.value)) {
      setSelectedWallets(prevWallets => [...prevWallets, selectedOption]);
    }
  };

  return (
    <div className="transaction-hub-page page-inner">
      <div className="page-header flex-row flex-align-space-between-center">
        <span className="page-title">Transaction Hub</span>
      </div>
      {!selectedAsset ?
        <div className="flex-row flex-align-space-evenly-center flex-gap-30 md-flex-wrap">
          {walletCards.map((card, index) => (
            <WalletCard
              key={index}
              walletType={card.walletType}
              total={card.total}
              percentageChange={card.percentageChange}
              description={card.description}
              icon={card.icon}
              onSelect={handleSelectAsset}
              currency={card.walletType}
            />
          ))}
        </div>
        :
        <div className='asset-selected-container flex-row flex-gap-150 md-flex-wrap'>
          <div className='asset-card-container'>
            <WalletCard {...selectedAsset} selected={true} currency={selectedAsset.walletType}/>
          </div>
          <div className='wallets-container flex-column flex-gap-30'>
            <div className='flex-row flex-gap-10 flex-align-start-end'>
              <AppFormSelectInput
                name='walletAddress'
                label='Search'
                control={form.control}
                options={labelValueOptions || []}
              />
              <Button
                label='Add'
                theme='form-outline'
                onClick={handleAddWallet}
              />
            </div>
            <div className="wallet-addresses-container flex-column flex-align-start-start flex-gap-15">
              {selectedWallets && selectedWallets.map((wallet: any) => (
                <div
                  key={wallet.walletAddress}
                  className={`wallet-address-container flex-row flex-align-start-center flex-gap-10`}
                  style={{ cursor: 'pointer' }}
                >
                  <span className="wallet-alias">{wallet.value}</span>
                  <span style={{ fontWeight: 'bold' }}>{'|'}</span>
                  <p className="wallet-address">{wallet.label}</p>

                  <RemoveWalletIcon

                    style={{ marginLeft: '30px', cursor: 'pointer' }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      }
      {selectedAsset &&
        <div className="page-content flex-column flex-gap-20">
          <TableFilters />
          <TableContent columns={columns} data={data}></TableContent>
          <TableExport data={data}/>
        </div>
      }
    </div>
  )
}

export default TransactionHubPage