import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SimpleCheckIcon } from '../../../../assets/icons/check-input.svg';
import Button from '../../../../components/button';
import './approve-request.scss';
import { useEffect, useState } from 'react';
import { apiService } from '../../../../services/api.service';
import { PaymentDetails } from '../../../../models';

const CheckIcon = <SimpleCheckIcon width={12} height={14} />;

const tranferDetailsMock = {
    from: 'Treasury Vault',
    to: 'Main street Brokerage',
    asset: 'BTC',
    amount: {
        gross: '5,675,990',
        net: '4,512,900'
    }
}

const ApproveRequestPage = () => {
    const [payment, setPayment] = useState<PaymentDetails>();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const getPayment = async () => {
            if (!id)
                return;
            try {
                const response = await apiService.getPaymentDetails(id);
                return response.data;
            } catch (e) {
                return
            }
        }

        const getApprovers = async () => {
            if (!id)
                return;
            try {
                const response = await apiService.getPaymentApprovalsByPayment(id);
                return response.data;
            } catch (e) {
                return
            }
        }

        getPayment().then((payment) => {
            setPayment(payment);
        }
        )

    }, [id])

    const handleTasksForApproval = () => {
        navigate('/app/payment/tasks-for-approval');
    }

    const handleRejectTransfer = async () => {
        try {
        const response = await apiService.updatePaymentApproval(id, 'BLOCKED');
        navigate('/app/payment/tasks-for-approval');
        } catch(e) {
            console.log(e);
        }
    }

    const handleApproveRequest = async () => {
        try {
        const response = await apiService.updatePaymentApproval(id, 'APPROVED');
        navigate('/app/payment/tasks-for-approval');
    } catch(e) {
        console.log(e);
    }
    }

    return (
        <div className="approve-request-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Approve request</span>
                <Button
                    label="Tasks for Approval"
                    icon={() => CheckIcon}
                    onClick={handleTasksForApproval}
                />
            </div>
            <div className="page-content flex-row flex-gap-50">
                <div className='approve-text-section flex-column flex-gap-10'>
                    <h2 className='subtitle'>Received From</h2>
                    <span className='message-text'>You have just received a message approving a transfer from:</span>
                    <span className='fullname'>First Name Last Name</span>
                </div>
                <div className='transfer-details-section flex-1 flex-column flex-gap-50'>
                    <h2 className='subtitle'>Requests details</h2>
                    <div className='transfer-details-container flex-1 flex-column flex-gap-20'>
                        <div className='from-to-section flex-row flex-align-center-center width-100'>
                            <div className='item'>
                                <span className='label'>From</span>
                                <span className='value'>{payment?.fromWalletAddress}</span>
                            </div>
                            <div className='item'>
                                <span className='label'>To</span>
                                {payment?.toWalletAddress ?
                                    <span className='value'>{payment?.toWalletAddress}</span> :
                                    <span className='value trunctated-text'>{payment?.toExternalWalletAddress}</span>
                                }
                            </div>
                        </div>
                        <div className='separator'></div>

                        <div className='asset-amount-section flex-row width-100'>
                            <div className='item flex-1'>
                                <span className='label'>Asset</span>
                                <span className='value'>{payment?.currency}</span>
                            </div>
                            <div className='item flex-1'>
                                <span className='label'>Amount</span>
                                <div className='amount-item flex-1'>
                                    <div className='amount-section flex-row flex-gap-10'>
                                        <span className='amount'>{payment?.amount}</span>
                                        <span className='amount'>{payment?.currency}</span>
                                    </div>
                                    <div className='separator'></div>
                                    <div className="sub-amounts">
                                        <div className='sub-options flex-row flex-gap-5'>
                                            <span className='sub-option active'>Net</span>
                                            <span className="sub-option">|</span>
                                            <span className='sub-option'>Gross</span>
                                        </div>
                                        <div className='sub-options flex-row flex-gap-5'>
                                            <span className='sub-option active'>{payment?.amount}</span>
                                            <span className="sub-option">|</span>
                                            <span className="sub-option">{payment?.amount}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buttons-container flex-row flex-align-space-between-center flex-gap-30">
                        <Button
                            theme="form-outline"
                            label="Reject transfer"
                            onClick={handleRejectTransfer}
                        ></Button>
                        <Button 
                        theme="form" 
                        label="Approve transfer"
                        onClick={handleApproveRequest}
                        ></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApproveRequestPage