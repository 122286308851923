
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import AppFormSelect from '../../../../components/form/app-form-select';
import AppFormInput from '../../../../components/form/app-form-input';
import AppFormCheckboxInput from '../../../../components/form/app-form-checkbox-input';
import Button from '../../../../components/button';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import './add-account-page.part.add-wallet.scss';
import { Asset, Network } from '../../../../models';
import { WalletInfo } from '../../../../models';


const assets = [
    { label: 'BTC', value: Asset.BTC },
    { label: 'ETH', value: Asset.ETH },
    { label: 'USDT', value: Asset.USDT },
]


const initialNetworks: Network[] = [
    {
        title: 'BTC',
        value: 'BTC20',
        min: '1.00 USDT',
        depositArrival: 5,
        acceptedAssets: [Asset.BTC]
    },
    {
        title: 'Ethereum',
        value: 'ERC20',
        min: '1.00 USDT',
        depositArrival: 64,
        acceptedAssets: [Asset.ETH, Asset.USDT]
    },
]

interface PartAddWalletProps {
    wallet: WalletInfo | null; 
    onSave: (wallet: WalletInfo) => void; 
}

const PartAddWallet: React.FC<PartAddWalletProps> = ({wallet, onSave}) => {
    const [networks, setNetworks] = useState<Network[] | []>([]);
    const form = useForm<WalletInfo>({
        mode: 'onTouched',
        defaultValues: wallet || {
            asset: '',
            walletAddress: '',
            walletAlias: '',
            network: {}, 
        },
    });
    
    const asset  = useWatch({
        control: form.control,
        name: 'asset',
    });

    
    
    useEffect(() => {
        if (asset) {
            const filteredNetworks = initialNetworks.filter((network) =>
                network.acceptedAssets.includes(asset as Asset)
            );

            if (filteredNetworks.length > 0) {
                form.setValue('network', filteredNetworks[0]);
                setNetworks(filteredNetworks);
            } else {
                form.setValue('network', null);
                setNetworks([]);
            }
        } else {
            form.setValue('network', null);
            setNetworks([]);
        }
        
    }, [asset, form.reset]);

    useEffect(() => {
        if (wallet) {
            form.reset(wallet); 
        } else {
            form.reset({
                asset: '',
                walletAddress: '',
                walletAlias: '',
                network: networks[0],
            });
        }
    }, [wallet, form.reset]);

    const onSubmit = (data: WalletInfo) => {
        onSave(data); 
    }
    return (
        <>
            <AppFormSelect
                label="Select Asset"
                name="asset"
                options={assets}
                control={form.control}
                placeholder="Asset"
                validations={{ required: true }}
            />
            <div className="networks-container flex-column flex-align-start-start flex-gap-10">
                <span className="networks-label">Select Network</span>
                <Controller
                    name="network"
                    control={form.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <>
                            {networks.map((network) => (
                                <div
                                    key={network.value}
                                    onClick={() => field.onChange(network)}  
                                    className={`network-container flex-column flex-gap-10 ${field.value?.value === network.value && 'selected'}`}
                                >
                                    <div className="flex-row flex-align-space-between-center">
                                        <span className="title">{network.title}{` (${network.value})`}</span>
                                       <CheckIcon className={`check-icon ${field.value?.value === network.value && 'selected'}`} />
                                    </div>
                                    <div className="flex-column flex-align-start-start flex-gap-5">
                                        <p className="subtitle">Min. deposit amount: <span>{network.min}</span></p>
                                        <p className="subtitle">Deposit arrival: <span>{network.depositArrival}</span></p>
                                    </div>
                                </div>
                            ))}
                         </>
                    )}
                />
            </div>
            <div className="form-container-100">
                <AppFormInput
                    name="walletAddress"
                    control={form.control}
                    label="Add Wallet Address"
                    validations={{ required: true }}
                />
            </div>
            <div className="form-container-100 flex-row flex-align-end-end flex-gap-10">
                <AppFormInput
                    name="walletAlias"
                    control={form.control}
                    label="Add Alias (Wallet Name)"
                    validations={{ required: true }}
                />
                 <div className="flex-column flex-align-center-end flex-gap-3">
                    <Button 
                    theme="form-outline" 
                    label={wallet ? 'Modify' : 'Add'} 
                    onClick={form.handleSubmit(onSubmit)} 
                    />
                </div>
            </div>
           
         </>
    );
};

export default PartAddWallet;
