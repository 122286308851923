// src/store/loadingSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: false,
}

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const loadingReducer = loadingSlice.reducer;
export const loadingSliceActions = {
  startLoading: loadingSlice.actions.startLoading,
  stopLoading: loadingSlice.actions.stopLoading,
};
