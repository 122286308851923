import './nav-bar.scss';
import { ReactComponent as BurgerMenu } from '../assets/icons/burger-menu.svg';
import { ReactComponent as NotificationIcon } from '../assets/icons/notifications.svg';
import { ReactComponent as UKFlag } from '../assets/icons/uk-flag.svg';
import { ReactComponent as SimpleCaret } from '../assets/icons/simple-caret.svg';
import { ReactComponent as ProfileIcon } from '../assets/icons/profile-icon.svg';
import { ReactComponent as OutlineCaret } from '../assets/icons/outline-caret.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSliceActions, UserState } from '../store/user.slice';
import { localStorageService, StorageKey } from '../services/local-storage.service';
import { useNavigate } from 'react-router-dom';
import { AppStore } from '../store/store';

const fireForgeLogo = require('../assets/icons/fire-forge-logo.png');

interface NavBarProps {
    toggleMenu: () => void;
}

const NavBar: React.FC<NavBarProps> = ({toggleMenu}) => {
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const userMenuRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector<AppStore, UserState>(store => store.user);

    const handleLogout = () => {
        localStorageService.removeItem(StorageKey.ACCESS_TOKEN);
        localStorageService.removeItem(StorageKey.REFRESH_TOKEN);
        dispatch(userSliceActions.removeUser());
        navigate('/auth');
    }
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
                setUserMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [userMenuRef]);

    return (
        <div className="navbar-container flex-row flex-align-start-center">
            <div className='logo-container flex-row flex-align-start-center flex-gap-5 '>
                <img src={fireForgeLogo} alt="Fire Forge Logo" />
                <div className='logo-title'>
                    <span className="title-primary">FIRE</span><span className="title-secondary">FORGE</span>
                </div>
            </div>
            <div className='burger-menu-and-search-container flex-row flex-align-start-center flex-gap-25 '>
                <BurgerMenu className='hide-md-gt' onClick={toggleMenu} />
                <div className='searchbar'>
                    <span>Search</span>
                </div>
            </div>
            <div className='buttons-container flex-row flex-align-end-center flex-gap-25 width-100'>
                <NotificationIcon />
                <UKFlag className='hide-sm'/>
                <span className='language-text hide-sm'>English</span>
                <SimpleCaret className='hide-sm'/>
                <ProfileIcon className='hide-sm' />
                <div className='user-info flex-column flex-align-start-start flex-gap-5 hide-sm'>
                    <span className='username'>
                        {user?.firstName} {' '} {user?.lastName}
                    </span>
                    <span className='role'>
                        {}
                    </span>
                </div>
                <div className='user-menu hide-sm' ref={userMenuRef}>
                    <OutlineCaret onClick={() => setUserMenuOpen(!userMenuOpen)} />
                    {
                        userMenuOpen && (
                            <div className='usermenu-list'>
                                <div
                                    className="dropdown-option"
                                    onClick={handleLogout}
                                >
                                    Logout
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default NavBar;