import React, { useRef } from 'react';
import './app-textarea.scss';
import { ForwardedProps } from '../../../types/component-props.types';

interface AppTextAreaProps extends ForwardedProps {
  placeholder?: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: any;
  readOnly?: boolean;
  rows?: number;
}

const AppTextArea: React.FC<AppTextAreaProps> = ({
  value,
  placeholder,
  onBlur,
  onChange,
  id,
  readOnly,
  rows = 3,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

 

  return (
    <div className="app-textarea-container">
      <textarea
        ref={textAreaRef}
        id={id}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
        rows={rows}
        className="app-textarea"
      />
    </div>
  );
};

export default AppTextArea;
