import AppRouter from './router/router';
import './i18n';
import { Provider } from 'react-redux';
import { store } from './store/store';
import AppInit from './AppInit';

function App() {
  return (
    <>
      <Provider store={store}>
        <AppInit>
          <AppRouter />
        </AppInit>
      </Provider >
    </>
  );
}

export default App;
