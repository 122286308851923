import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import AssetCard from "../../../../components/asset-card";
import TableContent from "../../../../components/table-content";
import { ReactComponent as BitcoinRoundedIcon } from '../../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthereumRoundedIcon } from '../../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as USDTRoundedIcon } from '../../../../assets/icons/usdt-rounded.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye.svg';
import './transactions-list-page.scss';
import { apiService } from "../../../../services/api.service";
import LoadingSpinner from "../../../../components/loading-spinner";
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { loadingSliceActions, LoadingState } from '../../../../store/loading.slice';
import { UserState } from '../../../../store/user.slice';
import { dateFormatter } from '../../../../utils/date-utils';
import CopyText from '../../../../components/copy-text';
import TableExport from '../../../../components/table-export';

enum AssetType {
  'BTC' = 'Bitcoin',
  'ETH' = 'Ethereum',
  'USDT' = 'USDT',
}

const TransactionsListPage = () => {
  const [transactions, setTransactions] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [walletBalance, setWalletBalance] = useState(0);
  const { isLoading } = useSelector<AppStore, LoadingState>(state => state.loading);
  const { user } = useSelector<AppStore, UserState>(state => state.user);
  const { walletAlias, walletAddress, balance, asset, status } = location.state || {};

  useEffect(() => {
    const fetchData = async () => {
      if (!walletAddress) return;
      dispatch(loadingSliceActions.startLoading())
      try {
        const balanceData = await apiService.getWalletBalance(walletAddress);
        const transactionsData = await apiService.getTransactionsByWallet(walletAddress);
        setWalletBalance(balanceData.data);
        setTransactions(transactionsData.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        dispatch(loadingSliceActions.stopLoading())
      }
    };

    fetchData();
  }, [walletAddress, user?.id]);

  const columns = [
    {
      Header: 'Transaction Hash',
      accessor: 'txHash',
      Cell: ({ value }: { value: string }) => (
        <div className="transaction-hash-cell">
          <div className="icon-wrapper" onClick={() => handleIconClick(value)} role="button">
            <EyeIcon />
          </div>
          <span className="truncated-text">{value}</span>
          <CopyText value={value}/>
        </div>
      ),
    },
    {
      Header: 'Method',
      accessor: 'walletReferenceStatus',
      Cell: ({ value }: { value: string }) => (
        <span className={`${value.toLocaleLowerCase()}`}>{value}</span>
      ),
    },
    {
      Header: 'Status',
      accessor: 'blockNumber',
      Cell: ({ value }: { value: string }) => (
        <span className='status-chip-account-details active'>Approved</span>
      ),
    },
    {
      Header: 'Timestamp',
      accessor: 'date',
      Cell: ({ value}: { value: string }) => { 
        return (
        <span>{dateFormatter(value)}</span>
      )
      }
    },
    {
      Header: 'From',
      accessor: 'from',
      Cell: ({ value, row }: { value: string, row: any }) => {return <>{
        row.original.fromAlias ? (<div className="from-to flex-column">
            <span>{row.original.fromAlias}</span>
            <span className='truncated-text secondary-text'>{value}</span>
        </div>) : 
        (<div className="from-to flex-column">
            <span className='truncated-text'>{value}</span>
        </div>)
    }</>}
    },
    {
      Header: 'To',
      accessor: 'to',
      Cell: ({ value, row }: { value: string, row: any }) => {return <>{
        row.original.fromAlias ? (<div className="from-to flex-column">
            <span>{row.original.fromAlias}</span>
            <span className='truncated-text secondary-text'>{value}</span>
        </div>) : 
        (<div className="from-to flex-column">
            <span className='truncated-text'>{value}</span>
        </div>)
    }</>}
    },
    {
      Header: 'Amount',
      accessor: 'value',
    },
  ];

  const renderAssetIcon = () => {
    switch (asset) {
      case 'BTC':
        return <BitcoinRoundedIcon />;
      case 'ETH':
        return <EthereumRoundedIcon />;
      case 'USDT':
        return <USDTRoundedIcon />;
      default:
        return null;
    }
  };

  const handleIconClick = (transactionHash: string) => {
    navigate('/app/wallet-management/transactions-details', { state: { transactionHash } });
  };

  const assetFullName = AssetType[asset as keyof typeof AssetType];

  
  return (
    <div className="transactions-list-page page-inner">
      <div className="page-header flex-row flex-align-space-between-center">
        <span className="page-title">Transactions List</span>
        <div className="wallet-info flex-row flex-align-center-center flex-gap-5 hide-sm">
          {renderAssetIcon()}
          <div className="asset-info flex-row flex-align-center-center flex-gap-20">
            <span className="token">Token</span>
            <span className="asset">{assetFullName}</span>
            <span className="asset">{`(${asset})`}</span>
          </div>
        </div>
      </div>
      <div className="flex-row flex-align-space-evenly-center flex-gap-30">
        <AssetCard walletAlias={walletAlias} walletAddress={walletAddress} balance={walletBalance?.toString()} status={status} asset={asset} />
      </div>
      <div className="page-content">
          {isLoading && !transactions.length   ? <LoadingSpinner /> : <TableContent columns={columns} data={transactions || []} />}
      </div>
    </div>
  );
}

export default TransactionsListPage;
