import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import './custom-chart.scss';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, Filler);

interface CustomChartProps {
    data: number[]; // Accept data as prop
}

const CustomChart: React.FC<CustomChartProps> = ({ data }) => {
    const chartData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Transaction Volume',
                data: data, // Use the data prop here
                borderColor: '#3E82F7',
                borderWidth: 2,
                pointBackgroundColor: '#3E82F7',
                pointBorderColor: '#3E82F7',
                pointHoverBackgroundColor: '#F2AC34',
                pointHoverBorderColor: '#F2AC34',
                pointHoverRadius: 5,
                tension: 0.1,
                fill: true,
                backgroundColor: (context: any) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea) {
                        return null;
                    }

                    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                    gradient.addColorStop(0, 'rgba(62, 130, 247, 0.5)');
                    gradient.addColorStop(1, 'rgba(62, 130, 247, 0)');
                    return gradient;
                },
            },
        ],
    };

    const firstDataValue = data[0]; // Use the first value in the provided data

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#FFF',
                    padding: 30,
                },
            },
            y: {
                grid: {
                    color: '#E2E2E2',
                    lineWidth: 0.2,
                    drawBorder: false,
                },
                beginAtZero: false,
                min: Math.floor(firstDataValue / 20) * 20, 
                ticks: {
                    stepSize: 20,
                    padding: 30,
                    color: '#FFF',
                    callback: (value: number | string) => `${value} Transactions`, 
                },
                suggestedMax: 100,
            },
        },
        plugins: {
            tooltip: {
                enabled: true,
                mode: 'index' as const,
                intersect: false,
            },
            legend: {
                display: false,
            },
        },
    };

    return (
        <div className='custom-chart-container'>
            <div className='custom-chart'>
                <Line data={chartData} options={options} />
            </div>
        </div>
    );
};

export default CustomChart;
