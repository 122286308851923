import { RouteObject } from "react-router-dom";
import WalletManagementPage from "./wallet-management/wallet-management-page";
import AddCryptoWalletPage from "./add-crypto-wallet/add-crypto-wallet-page";
import TransactionsListPage from "./transactions-list/transactions-list-page";
import TransactionsDetailsPage from "./transactions-details/transactions-details-page";

const walletManagementRouter: RouteObject[] = [
    {
        path: '',
        element: <WalletManagementPage />,
    }, 
    {
        path: 'add-crypto-wallet',
        element: <AddCryptoWalletPage/>
    },
    {
        path: 'transactions-list',
        element: <TransactionsListPage/>
    },
    {
        path: 'transactions-details',
        element: <TransactionsDetailsPage/>
    }

]

export default walletManagementRouter;