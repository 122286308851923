import { RouteObject } from "react-router-dom";
import AssetManagementPage from "./asset-management/asset-management";
import LiquidityManagementPage from "./liquidity-management/liquidity-management";
import RiskManagementPage from "./risk-management/risk-management-page";

    const  treasuryRouter: RouteObject[] = [
        {
            path: '',
            element: <AssetManagementPage/>,
                
        },
        {
            path: 'liquidity',
            element: <LiquidityManagementPage/>,
        },
        {
            path: 'risk',
            element: <RiskManagementPage/>
        }
       
]

export default treasuryRouter;