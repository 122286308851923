import './initiate-transfer-page.scss';
import { ReactComponent as SimpleCheckIcon } from '../../../../assets/icons/check-input.svg';
import Button from '../../../../components/button';
import { Controller, useForm, useWatch } from 'react-hook-form';
import AppFormSelect from '../../../../components/form/app-form-select';
import SwitchToggle from '../../../../components/switch-toggle';
import AppFormInput from '../../../../components/form/app-form-input';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WalletInfoDetails, WalletType } from '../../../../models/wallet.model';
import { apiService } from '../../../../services/api.service';
import { LabelValue } from '../../../../types/label-value.types';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';

const CheckIcon = <SimpleCheckIcon width={12} height={14} />;

interface InitiateTransferForm {
    currency: string;
    from: string;
    to: string;
    amount: string;
}

const assets = [
    { label: 'BTC', value: 'BTC' },
    { label: 'ETH', value: 'ETH' },
    { label: 'USDT', value: 'USDT' },
];

const toggleElements = [
    {
        type: 'unlisted',
        name: 'Unlisted Wallet',
    },
    {
        type: 'predefined',
        name: 'Predefined Wallet',
    },
];

const formatWithCommas = (value: string) => {
    if (!value) return '';
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const calculateNetAmount = (gross: string) => {
    const grossValue = parseFloat(gross.replace(/,/g, '')) || 0;
    const netValue = grossValue * (1 - 0.0003);
    return formatWithCommas(netValue.toFixed(2));
};

const InitiateTransferPage = () => {
    const navigate = useNavigate();
    const [internalWallets, setInternalWallets] = useState<WalletInfoDetails[]>([]);
    const [externalWallets, setExternalWallets] = useState<WalletInfoDetails[]>([]);
    const [activeToggle, setActiveToggle] = useState('predefined');
    const userState = useSelector<AppStore, UserState>(store => store.user);


    const internalWalletsOptions: LabelValue<string>[] = internalWallets?.map((wallet) => ({
        label: wallet.walletAddress,
        value: wallet?.walletAddress,
    }));

    const externalWalletsOptions: LabelValue<string>[] = externalWallets?.map((wallet) => ({
        label: wallet.walletAddress,
        value: wallet?.walletAddress,
    }));

    const form = useForm<InitiateTransferForm>({
        mode: 'onTouched',
        defaultValues: {
            currency: 'ETH',
            amount: '0',
        }
    });

    const amount = useWatch({
        control: form.control,
        name: 'amount',
    });

    const selectedCurrency = useWatch({
        control: form.control,
        name: 'currency',
    });


    useEffect(() => {
        form.reset({
            currency: selectedCurrency,
            from: '',
            to: '',
            amount: '',
        });
    }, [selectedCurrency]);


    useEffect(() => {
        const getWallets = async (walletType: WalletType) => {
            try {
                const response = await apiService.getWalletsByTypeAndAssetAndStatus(walletType, selectedCurrency,'ACTIVE')
                return response.data;
            } catch (e) {
                return [];
            }
        }

        getWallets(WalletType.INT).then((walletsData) => {
            setInternalWallets(walletsData)
        })

        getWallets(WalletType.EXT).then((walletsData) => {
            setExternalWallets(walletsData)
        })
    }, [selectedCurrency])


    useEffect(() => {
        form.setValue('to', '');

    }, [activeToggle])



    const handleTasksForApproval = () => {
        navigate('/app/payment');
    }

    const changeToggle = (value: string) => {
        setActiveToggle(value);
    };

    const handleGrossAmountChange = (value: string, onChange: any) => {

        const validValue = value.replace(/[^0-9.,]/g, '');

        const numericValue = validValue.replace(/,/g, '');

        const formattedValue = formatWithCommas(numericValue);

        onChange(formattedValue);
    };



    const onSubmit = async (data: InitiateTransferForm) => {
        
        const fromWalletId = internalWallets.find(wallet => wallet.walletAddress === data.from)?.id;
        
        let toWallet;
        if (activeToggle === 'predefined') {
            toWallet = externalWallets.find(wallet => wallet.walletAddress === data.to)?.id;
        }
        else {
            toWallet = data.to;
        }
        const amountToNumber =  parseFloat(data.amount.replace(/,/g, '')) || 0;
 
        
        
        try {
            await apiService.createPayment({
                status: 'PENDING',
                userId: userState?.user?.id!,
                currency: selectedCurrency,
                fromWalletId: fromWalletId!,
                toWalletId: typeof toWallet === 'number' ? toWallet : undefined,
                toExternalWalletAddress: typeof toWallet === 'string' ? toWallet : undefined,
                amount: amountToNumber
            });
            navigate('/app/payment')
        }   catch (e) {
            console.log(e);
            
        } 
    }

    return (
        <div className="initiate-transfer-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Initiate a transfer</span>
                <Button
                    label="Tasks for Approval"
                    icon={() => CheckIcon}
                    onClick={handleTasksForApproval}
                />
            </div>
            <div className="page-content flex-gap-40">
                <div className='transfer-details-container flex-column flex-gap-25'>
                    <AppFormSelect
                        label="Select Currency"
                        name="currency"
                        options={assets}
                        control={form.control}
                        placeholder="Currency"
                        validations={{ required: true }}
                    />
                    <AppFormSelect
                        label="From"
                        name="from"
                        options={internalWalletsOptions}
                        control={form.control}
                        placeholder="Select your wallet"
                        validations={{ required: true }}
                    />
                    <div className='to-container'>
                        {activeToggle === 'predefined' ? (
                            <AppFormSelect
                                label="To"
                                name="to"
                                options={externalWalletsOptions}
                                control={form.control}
                                placeholder="Select wallet"
                                validations={{ required: true }}
                            />
                        ) : (
                            <AppFormInput
                                label="To"
                                name="to"
                                control={form.control}
                                validations={{ required: true }}
                                placeholder="Wallet address"
                            />
                        )}
                        <SwitchToggle elements={toggleElements} activeElement={activeToggle} onChange={changeToggle} />
                    </div>
                    <div className='amount-button-container flex-row flex-align-space-between-end flex-gap-15'>
                        <div className="amount-input-container">
                            <label className="amount-label">Amount</label>
                            <Controller
                                name="amount"
                                control={form.control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <div className="amount-input">
                                        <div className="main-amount">
                                            <input
                                                type="text"
                                                className="amount-value-input"
                                                value={value}
                                                onChange={(e) => handleGrossAmountChange(e.target.value, onChange)}
                                                placeholder="Enter amount"
                                            />
                                            <span className="currency">{selectedCurrency}</span>
                                        </div>
                                        <div className="sub-amounts">
                                            <div className='sub-options flex-row flex-gap-5'>
                                                <span className='sub-option active'>Net</span>
                                                <span className="sub-option">|</span>
                                                <span className='sub-option'>Gross</span>
                                            </div>
                                            <div className='sub-options flex-row flex-gap-5'>
                                                <span className='sub-option active'>{calculateNetAmount(amount)}</span>
                                                <span className="sub-option">|</span>
                                                <span className="sub-option">{amount || 0.00}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <Button
                            theme='form'
                            label='Initiate Transfer'
                            disabled={!form.formState.isValid}
                            onClick={form.handleSubmit(onSubmit)} />
                    </div>
                </div>
                <div className='transfer-info-container flex-column flex-gap-25'>
                    <span className='waiting-text'>Waiting for transfer...</span>
                    <span className='initiate-text'>Initiate the transfer to approve it</span>
                </div>
            </div>
        </div>
    );
};

export default InitiateTransferPage;
