import { AxiosInstance } from 'axios';
import { localStorageService, StorageKey } from '../services/local-storage.service';
import { authService } from '../services/auth.service';
import { userSliceActions } from '../store/user.slice';
import { Dispatch } from '@reduxjs/toolkit';
import { loadingSliceActions } from '../store/loading.slice';

export function attachAxiosLogInterceptors(
  axios: AxiosInstance,
) {
  function getRequestText(req: any) {
    return `${req.method.toUpperCase()} ${req.url || req.path}`;
  }

  axios.interceptors.request.use(
    req => {
      console.log(`Sending request '${getRequestText(req)}' with data: ${JSON.stringify({
        headers: req.headers,
        body: req.data
      })}`);
      return req;
    }
  );

  axios.interceptors.response.use(
    res => {
      console.log(
        `Received response from '${getRequestText(res.config)}': ` +
        `${res.status} Status Code with data: ${JSON.stringify({
          headers: res.headers,
          body: res.data
        })}`
      );
      return res;
    },
    err => {
      if (err.response) {
        // HTTP error response
        console.warn(
          `Received error response from '${getRequestText(err.config)}': ` +
          `${err.response.status} Status Code with data: ${JSON.stringify({
            headers: err.response.headers,
            body: err.response.data
          })}`
        );
      } else {
        // unexpected error
        console.error(
          `Unexpected error: ${JSON.stringify({
            err,
            trace: typeof err === 'object' ? err.trace || undefined : undefined
          })}`,
        );
      }

      throw err;
    }
  );
}

export function attachAxiosApiInterceptors(
  axios: AxiosInstance,
  dispatch: Dispatch<any>
) {
  axios.interceptors.request.use(
    async req => {

      const accessToken = localStorageService.getItem(StorageKey.ACCESS_TOKEN);

      if (accessToken) {
        req.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      return req;
    }
  );

  axios.interceptors.response.use(
    response => response,

    async error => {

      const originalRequest = error.config;

      if (error?.response?.status === 401 && !originalRequest._retry) {
        dispatch(loadingSliceActions.startLoading())
        originalRequest._retry = true;
        try {
          const refresh_token = localStorageService.getItem(StorageKey.REFRESH_TOKEN);
          if (!refresh_token) {
            throw new Error('No refresh token available');
          }

          await authService.refreshLogin(refresh_token);
          const accessToken = localStorageService.getItem(StorageKey.ACCESS_TOKEN);
          axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          return axios.request(originalRequest).finally(() => {
            dispatch(loadingSliceActions.stopLoading());  
          });
        } catch (refreshError) {
          localStorageService.removeItem(StorageKey.ACCESS_TOKEN);
          localStorageService.removeItem(StorageKey.REFRESH_TOKEN);
          dispatch(userSliceActions.removeUser());
          window.location.href = '/auth';
          return refreshError;
        }
      }
      return error;
    }
  );
}