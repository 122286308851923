import TableContent from "../../../../components/table-content";
import Button from "../../../../components/button";
import './supplier-details-page.scss';
import { ReactComponent as MultiplePeopleIcon } from '../../../../assets/icons/multiple-users.svg';
import { useNavigate, useParams } from "react-router-dom";
import { apiService } from "../../../../services/api.service";
import { useEffect, useState } from "react";
import { WalletInfoDetails } from "../../../../models";
import { useSelector } from "react-redux";
import { AppStore } from "../../../../store/store";
import { UserState } from "../../../../store/user.slice";




const SupplierDetailsPage = () => {

  const columns = [
    {
      Header: 'Wallets',
      accessor: 'walletAlias',
    },
    {
      Header: 'Wallet Address',
      accessor: 'walletAddress',
    },
    {
      Header: 'Balance',
      accessor: 'balance',
    },
    {
      Header: 'Asset',
      accessor: 'asset',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value,row }: { value: string, row:any }) => (
        <span
        className={`status-chip-account-details ${row.original.status?.toLowerCase()}`}
        style={{ cursor: isDirector ? 'pointer' : 'default' }}
        onClick={() => isDirector && changeWalletStatus(row.original.id, row.original.status)}
      >
        {row.original.status}
      </span>
      ),
    },
    {
      Header: 'Transactions',
      accessor: 'transactions',
      Cell: ({ row }: { row: any }) =>
        <span
          className="view-link"
          onClick={() => handleViewTransactions(row.original)}
        >View
        </span>,
    },
  ]

  const { id } = useParams<{ id: string }>();
  const [accountData, setAccountData] = useState<WalletInfoDetails[]>([]);
  const navigate = useNavigate();
  const { user } = useSelector<AppStore, UserState>(state => state.user)
  const isDirector = user?.roles?.includes('director');

  useEffect(() => {
    const fetchAccountDetails = async (accountId: string) => {

      try {
        const response = await apiService.getWalletByAccount(+accountId);
        return response.data
      } catch (error) {
      }
    };

    if (id) {
      fetchAccountDetails(id).then((data) => setAccountData(data));
    }
  }, [id]);

  const changeWalletStatus = async (walletId: number, currentStatus: string) => {
    if (!isDirector) {
      return;
    }
    const newStatus = currentStatus.toUpperCase() === 'ACTIVE' ? 'BLOCKED' : 'ACTIVE';

    try {
      await apiService.updateWalletStatus(walletId, newStatus);
      setAccountData((prevData) =>
        prevData.map((wallet) =>
          wallet.id === walletId ? { ...wallet, status: newStatus } : wallet
        )
      );
    } catch (error) {
      console.error('Failed to update wallet status', error);
    }
  };

  const handleAccountList = () => {
    navigate('/app/supplier-management')
  }

  const handleViewTransactions = (walletData: any) => {

    navigate('/app/wallet-management/transactions-list', {
      state: {
        walletAlias: walletData.walletAlias,
        walletAddress: walletData.walletAddress,
        balance: walletData.balance,
        asset: walletData.asset,
        status: walletData.status,
      },
    });
  };


  return <div className="supplier-details-page page-inner">
    <div className="page-header flex-row flex-align-space-between-center">
      <span className="page-title">Supplier Details</span>
      <Button
        label="Supplier List"
        icon={MultiplePeopleIcon}
        onClick={handleAccountList}
      ></Button>
    </div>
    <div className="page-content flex-column flex-gap-20">
      <div className="table-header">
        <span className="table-title">Wallets</span>
      </div>
      <TableContent columns={columns} data={accountData || []}></TableContent>
    </div>
  </div>
}

export default SupplierDetailsPage;