import { createBrowserRouter, Navigate, RouterProvider, } from "react-router-dom";
import authRouter from "./public/auth-page.router";
import AuthLayout from "./public/auth-page.layout";
import AppLayout from "./private/app-layout";
import privateRouter from "./private/private-router";
import AuthGuard from "./public/auth-guard";

const appRouter = createBrowserRouter([
    {
      path: '',
      element: <Navigate to="/auth" replace />, 
    },
    {
      path: 'auth',
      element: <AuthGuard />, 
      children: authRouter, 
    },
    {
      path: 'app', 
      element: <AppLayout />,
      children: privateRouter, 
    },
  ]);

const AppRouter: React.FC = () => {
    return ( 
        <RouterProvider router={appRouter}/>
    )
}

export default AppRouter;