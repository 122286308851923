import StatisticalInfoCard from "../../../../components/statistical-info-card";
import { ReactComponent as BitcoinRoundedIcon } from '../../../../assets/icons/bitcoin-rounded.svg';
import { ReactComponent as EthereumRoundedIcon } from '../../../../assets/icons/ethereum-rounded.svg';
import { ReactComponent as USDTRoundedIcon } from '../../../../assets/icons/usdt-rounded.svg';
import { ReactComponent as PositiveIcon } from '../../../../assets/icons/positive.svg';
import { ReactComponent as NegativeIcon } from '../../../../assets/icons/negative.svg';
import { ReactComponent as BitcoinIcon } from '../../../../assets/icons/bitcoin-wallet.svg';
import { ReactComponent as USDTIcon } from '../../../../assets/icons/usdt-wallet.svg';
import { ReactComponent as EthereumIcon } from '../../../../assets/icons/ethereum-wallet.svg';
import { ReactComponent as BitcoinAssetsInsights } from '../../../../assets/icons/assets-insights.svg';
import './asset-management.scss';
import DoughnutChart from "../../../../components/doughnut-chart";
import CryptoCarousel from "../../../../components/custom-chart";


const items = [
    {
      title: 'Bitcoin BTC',
      image: <BitcoinIcon/>,
      price: '54,844.87',
      percentage: '+1.01%',
    },
    {
      title: 'Bitcoin Could Hit $90K by Year End',
      image: <BitcoinAssetsInsights/>,
      price: '',
      percentage: '',
    },
    {
      title: 'Ethereum ETH',
      image: <EthereumIcon/>,
      price: '10,342.65',
      percentage: '+35.01%',
    },
    // Add more items as needed
  ];



const AssetManagementPage = () => {

    return (
        <div className="asset-management-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Asset Management</span>
            </div>
            <div className="page-content limit-height">
                <StatisticalInfoCard
                    title="Total Portofolio Value"
                    value="40,689"
                    performanceToday="+ 200.000"
                    performanceChange="+ 2,98%"
                />
            </div>

            <div className="page-content flex-column flex-gap-20 limit-height">
                <div className="table-header">
                    <span className="table-title">Assets</span>
                </div>
                <div className="assets-container flex-row flex-gap-100 flex-align-start-center">
                    <div className="doughnut-chart-container">
                        <DoughnutChart theme="assets"/>
                    </div>
                    <div className="assets-info flex-column flex-gap-30">
                        <div className="asset-item flex-row flex-gap-40 flex-align-center-center">
                            <BitcoinRoundedIcon />
                            <span className="percentage">+ 4,15%</span>
                            <div className="helper flex-row flex-gap-10"><PositiveIcon />change over the last 24h</div>
                        </div>
                        <div className="asset-item flex-row flex-gap-40 flex-align-center-center">
                            <EthereumRoundedIcon />
                            <span className="percentage">+ 4,15%</span>
                            <div className="helper flex-row flex-gap-10"><PositiveIcon />change over the last 24h</div>
                        </div>
                        <div className="asset-item flex-row flex-gap-40 flex-align-center-center">
                            <USDTRoundedIcon />
                            <span className="percentage">+ 4,15%</span>
                            <div className="helper flex-row flex-gap-10"><PositiveIcon /> change over the last 24h</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content flex-column flex-gap-20 limit-height">
                <div className="table-header">
            </div>
            </div>
        </div>
    )
}

export default AssetManagementPage;