import { useState } from "react";
import CustomChart from "./custom-chart";
import AppSelect from "./form/base/app-select";
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import './transactions-details-chart.scss';



const yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
];

type YearType = '2021' | '2022' | '2023' | '2024';

const mockTransactionData: Record<YearType, number[]> = {
    '2021': [22, 30, 50, 90, 60, 100, 80, 50, 40, 70, 95, 70],
    '2022': [32, 40, 60, 45, 70, 55, 90, 60, 90, 80, 75, 80],
    '2023': [42, 90, 70, 60, 80, 65, 100, 70, 60, 90, 85, 90],
    '2024': [52, 60, 80, 80, 90, 75, 110, 80, 70, 100, 95, 100],
};


const TransactionsDetailsChart = () => {
    const [selectedYear, setSelectedYear] = useState<YearType>('2021');

    const changeYear = (value: any) => {
        setSelectedYear(value as YearType); 
    };

    return (
        <div className="transaction-details-chart page-content flex-column flex-gap-20">
            <div className="table-header">
                <span className="table-title">Transaction Details</span>
                <div className="filters-container flex-gap-20">
                    <AppSelect
                        options={yearOptions}
                        onChange={changeYear}
                        value={selectedYear}
                        icon={<CalendarIcon />}
                    />
                </div>
            </div>
            <CustomChart data={mockTransactionData[selectedYear]} /> 
        </div>
    );
};

export default TransactionsDetailsChart;