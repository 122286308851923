import React, { useState, useEffect } from 'react';
import './app-select-input.scss'; 

interface LabelValue<T = string> {
  label: string;
  value: T;
}

interface AppSelectInputProps<T = string> {
  options: LabelValue<T>[];  
  value?: T;  
  onChange: (value: T) => void;  
  placeholder?: string;  
}

const AppSelectInput = <T,>({ options, value, onChange, placeholder }: AppSelectInputProps<T>) => {
  const [inputValue, setInputValue] = useState<string>('');  
  const [filteredOptions, setFilteredOptions] = useState<LabelValue<T>[]>(options);  
  const [isOpen, setIsOpen] = useState(false); 

  useEffect(() => {
    setFilteredOptions(
      options.filter(option => 
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  }, [inputValue, options]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsOpen(true);  
  };

  const handleOptionClick = (option: LabelValue<T>) => {
    setInputValue(option.label);  
    onChange(option.value);  
    setIsOpen(false);  
  };
  
  return (
    <div className="app-dropdown-container">
      <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          className="app-input"
          onClick={() => setIsOpen(!isOpen)}
        />
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>
        </span>
      </div>
      {isOpen && filteredOptions.length > 0 && (
        <div className="dropdown-list">
          {filteredOptions.map((option) => (
            <div
              key={option.value?.toString()}
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppSelectInput;
