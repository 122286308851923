import { RouteObject } from "react-router-dom";
import TasksForApprovalPage from "./tasks-for-approval/tasks-for-approval-page";
import InitiateTransferPage from "./initiate-transfer/initiate-transfer-page";
import ApproveTransferPage from "./approve-transfer/approve-transfer";
import ApproveRequestPage from "./approve-request/approve-request";
import MyPaymentsPage from "./my-payments/my-payments-page";

const paymentRouter: RouteObject[] = [
    {
        path: '',
        element: <MyPaymentsPage/>
    },
    {
        path: 'tasks-for-approval',
        element: <TasksForApprovalPage/>
    },
    {
        path: 'initiate-transfer',
        element: <InitiateTransferPage/>
    },
    {
        path: 'approve-transfer',
        element: <ApproveTransferPage/>
    },
    {
        path: 'approve-request',
        element: <ApproveRequestPage/>
    }
]

export default paymentRouter;