import { Outlet, useNavigate } from "react-router-dom";
import './auth-page.layout.scss';
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const fireForgeLogo = require('../../assets/icons/fire-forge-logo.png');

const AuthLayout = () => {
    const { t } = useTranslation();
    return (
        <div className="auth-page-layout-container flex-column flex-align-center-center flex-gap-50">
            <div className="header-container flex-column flex-align-center-center">
                <img src={fireForgeLogo} alt="Fire Forge Logo" />
                <div className="title-container flex-column flex-align-center-center">
                    <div>
                    <span className="title-text-primary">FIRE</span><span className="title-text-secondary">FORGE</span>
                    </div>
                    <span className="subtitle-text">{t('auth.page.subtitle')}</span>
                </div>
            </div>
            <Outlet></Outlet>
        </div>
    );

}

export default AuthLayout;