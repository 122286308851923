import React, { PropsWithChildren } from 'react';
import './app-form-element-container.scss';
import { ForwardedProps } from '../../../types/component-props.types';

interface AppFormElementContainerProps extends ForwardedProps<PropsWithChildren> {
}

const AppFormElementContainer: React.FC<AppFormElementContainerProps> = (
  {children, className}
) => {
  return (
    <div className={`app-form-element-container ${className || ''}`}>
      {children}
    </div>
  );
}

export default AppFormElementContainer;
