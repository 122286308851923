const mockAnalytics = [
    { date: '2021-01-15', BTC: 25, ETH: 73, USDT: 45 },
    { date: '2021-02-15', BTC: 35, ETH: 62, USDT: 89 },
    { date: '2021-03-15', BTC: 45, ETH: 85, USDT: 51 },
    { date: '2021-04-15', BTC: 52, ETH: 74, USDT: 25 },
    { date: '2021-05-15', BTC: 38, ETH: 82, USDT: 35 },
    { date: '2021-06-15', BTC: 30, ETH: 65, USDT: 42 },
    { date: '2021-07-15', BTC: 42, ETH: 45, USDT: 65 },
    { date: '2021-08-15', BTC: 48, ETH: 52, USDT: 47 },
    { date: '2021-09-15', BTC: 54, ETH: 60, USDT: 55 },
    { date: '2021-10-15', BTC: 58, ETH: 73, USDT: 88 },
    { date: '2021-11-15', BTC: 65, ETH: 80, USDT: 75 },
    { date: '2021-12-15', BTC: 70, ETH: 90, USDT: 95 },

    { date: '2022-01-15', BTC: 40, ETH: 50, USDT: 85 },
    { date: '2022-02-15', BTC: 42, ETH: 62, USDT: 75 },
    { date: '2022-03-15', BTC: 45, ETH: 72, USDT: 90 },
    { date: '2022-04-15', BTC: 55, ETH: 65, USDT: 92 },
    { date: '2022-05-15', BTC: 48, ETH: 70, USDT: 78 },
    { date: '2022-06-15', BTC: 32, ETH: 55, USDT: 70 },
    { date: '2022-07-15', BTC: 50, ETH: 65, USDT: 88 },
    { date: '2022-08-15', BTC: 60, ETH: 75, USDT: 85 },
    { date: '2022-09-15', BTC: 70, ETH: 80, USDT: 78 },
    { date: '2022-10-15', BTC: 55, ETH: 60, USDT: 82 },
    { date: '2022-11-15', BTC: 65, ETH: 78, USDT: 65 },
    { date: '2022-12-15', BTC: 75, ETH: 85, USDT: 95 },

    { date: '2023-01-15', BTC: 50, ETH: 65, USDT: 85 },
    { date: '2023-02-15', BTC: 55, ETH: 72, USDT: 95 },
    { date: '2023-03-15', BTC: 60, ETH: 80, USDT: 70 },
    { date: '2023-04-15', BTC: 63, ETH: 85, USDT: 90 },
    { date: '2023-05-15', BTC: 68, ETH: 88, USDT: 65 },
    { date: '2023-06-15', BTC: 65, ETH: 90, USDT: 85 },
    { date: '2023-07-15', BTC: 72, ETH: 95, USDT: 90 },
    { date: '2023-08-15', BTC: 78, ETH: 98, USDT: 95 },
    { date: '2023-09-15', BTC: 70, ETH: 90, USDT: 88 },
    { date: '2023-10-15', BTC: 75, ETH: 85, USDT: 92 },
    { date: '2023-11-15', BTC: 80, ETH: 90, USDT: 90 },
    { date: '2023-12-15', BTC: 85, ETH: 95, USDT: 95 },

    { date: '2024-01-15', BTC: 88, ETH: 51, USDT: 21 },
    { date: '2024-02-15', BTC: 56, ETH: 98, USDT: 30 },
    { date: '2024-03-15', BTC: 36, ETH: 100, USDT: 15},
    { date: '2024-04-15', BTC: 60, ETH: 75, USDT: 85 },
    { date: '2024-05-15', BTC: 70, ETH: 80, USDT: 78 },
    { date: '2024-06-15', BTC: 55, ETH: 60, USDT: 82 },
    { date: '2024-07-15', BTC: 65, ETH: 78, USDT: 65 },
    { date: '2024-08-15', BTC: 75, ETH: 85, USDT: 95 },
    { date: '2024-09-15', BTC: 50, ETH: 65, USDT: 85 },
    { date: '2024-10-15', BTC: 55, ETH: 72, USDT: 95 },
    { date: '2024-11-15', BTC: 60, ETH: 80, USDT: 70 },
    { date: '2024-12-15', BTC: 60, ETH: 20, USDT: 70 },
]

export default mockAnalytics;
