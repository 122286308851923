import React from 'react';
import { Message } from 'react-hook-form/dist/types/errors';
import { ValidationRule } from 'react-hook-form/dist/types/validator';
import './app-form-element-label.scss';
import { ForwardedProps } from '../../../types/component-props.types';

interface Validations {
  required: Message | ValidationRule<boolean>;
}

interface AppFormElementLabelProps extends ForwardedProps{
  name: string;
  label: string;
  validations?: Partial<Validations>;
}

const AppFormElementLabel: React.FC<AppFormElementLabelProps> = (
  {name, label, validations, className}
) => {
  return (
    <div className={`app-form-element-label ${className ? className : ''}`}>
      <label htmlFor={name}>{label}{(validations?.required && (
        <span className="text-error">*</span>
      ))}</label>
    </div>
  );
}

export default AppFormElementLabel;
