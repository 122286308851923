import { useState, useEffect } from "react";
import AnalyticsChart, { Dataset } from "./analytics-chart";
import './asset-analytics.scss';
import AppSelect from "./form/base/app-select";
import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import mockAnalytics from '../mock-data/asset-analytics';

const quarterOptions = [
    { label: 'Q1', value: 'Q1' },
    { label: 'Q2', value: 'Q2' },
    { label: 'Q3', value: 'Q3' },
    { label: 'Q4', value: 'Q4' }
];

const yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
];



 

interface ChartData {
    labels: string[];
    datasets: Dataset[];
}

const quarterMonths: { Q1: number[]; Q2: number[]; Q3: number[]; Q4: number[] } = {
    Q1: [1, 2, 3],  
    Q2: [4, 5, 6],  
    Q3: [7, 8, 9], 
    Q4: [10, 11, 12] 
};

const getLabelsForSelectedQuarter = (quarter: keyof typeof quarterMonths) => {
    const months = quarterMonths[quarter];
    
    return months.map(month => {
        return new Date(0, month - 1).toLocaleString('default', { month: 'short' });
    });
};

const filterDataByQuarter = (data: any[], year: string, quarter: keyof typeof quarterMonths) => {
    const monthsToInclude = quarterMonths[quarter];
    return data.filter(item => {
        const itemDate = new Date(item.date);
        const itemYear = itemDate.getFullYear().toString();
        const itemMonth = itemDate.getMonth() + 1; 
        return itemYear === year && monthsToInclude.includes(itemMonth);
    });
};



const AssetAnalytics = () => {
    const [selectedQuarter, setSelectedQuarter] = useState<'Q1' | 'Q2' | 'Q3' | 'Q4'>('Q1');
    const [selectedYear, setSelectedYear] = useState('2021');
    const [filteredData, setFilteredData] = useState<ChartData>({
        labels: [],
        datasets: []
    });

    const changeQuarter = (value: any) => {
        setSelectedQuarter(value);
    };

    const changeYear = (value: any) => {
        setSelectedYear(value);
    };

    useEffect(() => {
        const data = filterDataByQuarter(mockAnalytics, selectedYear, selectedQuarter);

        const labels = getLabelsForSelectedQuarter(selectedQuarter);

        const chartData = {
            labels: labels, 
            datasets: [
                {
                    label: 'BTC',
                    data: data.map(item => item.BTC), 
                    borderColor: '#FCBE2D',
                    borderWidth: 2,
                    backgroundColor: '#FCBE2D',
                    tension: 0.2,
                    pointBorderColor: '#FCBE2D',
                    pointBorderWidth: 2,
                    pointRadius: 5,
                    pointHoverRadius: 6,
                    fill: false,
                },
                {
                    label: 'ETH',
                    data: data.map(item => item.ETH), 
                    borderColor: '#7F7F7F',
                    backgroundColor: '#7F7F7F',
                    tension: 0.2,
                    pointBorderColor: '#7F7F7F',
                    pointRadius: 5,
                    fill: false,
                },
                {
                    label: 'USDT',
                    data: data.map(item => item.USDT), 
                    borderColor: '#00B69B',
                    backgroundColor: '#00B69B',
                    tension: 0.2,
                    pointBorderColor: '#00B69B',
                    pointRadius: 5,
                    fill: false,
                },
            ],
        };
        setFilteredData(chartData);
    }, [selectedYear, selectedQuarter]);

    return (
        <div className='asset-analytics statistic-card flex-column flex-gap-20'>
            <div className='card-header flex-row'>
                <div className='card-title'>
                    <span>Asset Analytics</span>
                </div>
                <div className="filters-container flex-gap-20">
                    <AppSelect
                        options={yearOptions}
                        onChange={changeYear}
                        value={selectedYear}
                        icon={<CalendarIcon />}
                    />
                    <AppSelect
                        options={quarterOptions}
                        onChange={changeQuarter}
                        value={selectedQuarter}
                        icon={<CalendarIcon />}
                    />
                </div>
            </div>
            <div className='chart-container'>
                <AnalyticsChart data={filteredData} />
            </div>
        </div>
    );
};

export default AssetAnalytics;
