import Button from "./button";
import './table-export.scss';

const TableExport = ({ data }: { data: any[] }) => {
  
    const handleDownloadExcel = () => {
       return 
    };

    return (
        <div className="table-export-container flex-row flex-align-end-center">
            <Button 
                theme='filters-button'
                onClick={handleDownloadExcel}
                label='Download Excel Export' 
            />
        </div>
    );
};

export default TableExport;
