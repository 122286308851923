import { FieldValues, useForm } from "react-hook-form";
import AppFormInput from "../../components/form/app-form-input";
import './auth-page.login.scss';
import Button from "../../components/button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/auth.service";
import { apiService } from "../../services/api.service";
import { userSliceActions } from "../../store/user.slice";
import { useState } from "react";


interface LoginForm extends FieldValues {
    username: string;
    password: string;
}

const LoginPage: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const form = useForm<LoginForm>({
        mode: 'onTouched',
        defaultValues: {}
    });

    const onSubmit = async (data: LoginForm) => {
        setErrorMessage('')
        setLoading(true);
        try {
            const loginResult = await authService.login(data);
            
            const responseUserData = await apiService.getUserDetails(loginResult.userId);
            
            dispatch(userSliceActions.saveUser({
                ...responseUserData.data
            }))
            navigate('/app');
        } catch (e: any) {
             if (e.response && e.response.status === 401) {
                setErrorMessage('Incorrect username or password.');
            } else {
                setErrorMessage('An error occurred. Please try again.');
            }

        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="login-page-container flex-column flex-align-center-center flex-gap-30">
             {errorMessage && (
                <div className="error-message" style={{ color: 'red' }}>
                    {errorMessage}
                </div>
            )}
            <AppFormInput
                theme="auth"
                name="username"
                control={form.control}
                label="Username"
                validations={{ required: true }}
            />

            <AppFormInput
                theme="auth"
                name="password"
                control={form.control}
                label="Password"
                validations={{ required: true }}
                type="password"
            />

            <div className="flex-column flex-align-center-center flex-gap-30 width-100">

                <Button
                    theme="blue"
                    label="Login"
                    disabled={!form.formState.isValid || loading}
                    onClick={form.handleSubmit(onSubmit)}
                ></Button>

                <div className="reset-password-link">
                    <p>Forgot your password?
                        <span> Reset It!</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;