import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SimpleCheckIcon } from '../../../../assets/icons/check-input.svg';
import Button from '../../../../components/button';
import './approve-transfer.scss';
import { useEffect, useState } from 'react';
import { apiService } from '../../../../services/api.service';

const CheckIcon = <SimpleCheckIcon width={12} height={14} />;


interface PaymentDetails {
    amount: number;
    currency: string;
    description?: string;
    error?: string;
    fromWalletAddress: string;
    fromWalletId: number;
    id: number;
    status: string;
    toExternalWalletAddress?: string;
    toWalletAddress: string;
    toWalletId: number;
    transactionHash: string;
    userId: string;
}


interface Approver {
    
        id: number,
        paymentId: number,
        userId: string,
        status: string
        firstName: string
        lastName: string
      
}

const ApproveTransferPage = () => {
    const [payment, setPayment] = useState<PaymentDetails>();
    const [approversList, setApproversList] = useState<Approver[]>();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const getPayment = async () => {
            if (!id)
                return;
            try {
                const response = await apiService.getPaymentDetails(id);
                return response.data;
            } catch (e) {
                return
            }
        }

        const getApprovers = async () => {
            if(!id)
                return;
            try {
                const response = await apiService.getPaymentApprovalsByPayment(id);
                return response.data;
            } catch(e) {
                return
            }
        }

        getPayment().then((payment) => {
            setPayment(payment);
        }
        )
        getApprovers().then((approvers) => {
            setApproversList(approvers)
        })
    }, [id])
    const handleTasksForApproval = () => {
        navigate('/app/payment');
    }
    return (
        <div className="approve-transfer-page page-inner">
            <div className="page-header flex-row flex-align-space-between-center">
                <span className="page-title">Approve transfer</span>
                <Button
                    label="Tasks for Approval"
                    icon={() => CheckIcon}
                    onClick={handleTasksForApproval}
                />
            </div>
            <div className="page-content flex-row flex-gap-100">
                <div className='transfer-details-container flex-1 flex-column flex-gap-20 '>
                    <div className='from-to-section flex-row flex-align-center-center width-100'>
                        <div className='item'>
                            <span className='label'>From</span>
                            <span className='value'>{payment?.fromWalletAddress}</span>
                        </div>
                        <div className='item'>
                            <span className='label'>To</span>
                           { payment?.toWalletAddress  ? 
                           <span className='value'>{payment?.toWalletAddress}</span> : 
                           <span className='value trunctated-text'>{payment?.toExternalWalletAddress}</span>
                           }
                        </div>
                    </div>
                    <div className='separator hide-sm'></div>

                    <div className='asset-amount-section flex-row width-100'>
                        <div className='item flex-1'>
                            <span className='label'>Asset</span>
                            <span className='value'>{payment?.currency}</span>
                        </div>
                        <div className='item flex-1'>
                            <span className='label'>Amount</span>
                            <div className='amount-item flex-1'>
                                <div className='amount-section flex-row flex-gap-10'>
                                    <span className='amount'>{payment?.amount}</span>
                                    <span className='amount'>{payment?.currency}</span>
                                </div>
                                <div className='separator'></div>
                                <div className="sub-amounts">
                                    <div className='sub-options flex-row flex-gap-5'>
                                        <span className='sub-option active'>Net</span>
                                        <span className="sub-option">|</span>
                                        <span className='sub-option'>Gross</span>
                                    </div>
                                    <div className='sub-options flex-row flex-gap-5'>
                                        <span className='sub-option active'>{payment?.amount}</span>
                                        <span className="sub-option">|</span>
                                        <span className="sub-option">{payment?.amount}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='approve-list-container flex-1 flex-column flex-gap-20'>
                    <div className='subtitle flex-row flex-align-space-between-center' style={{ marginBottom: '10px' }}>
                        <span>Approve List</span>
                        <span className='status'>Status</span>
                    </div>
                    <div className='approval-items flex-row flex-column flex-gap-15'>
                        {
                            approversList?.map((item) =>
                            (
                                <div className='approval-item flex-row flex-align-space-between-center'>
                                    <p className='fullname'>{`${item.firstName}, ${item.lastName}`} <span className='helper'> waiting...</span></p>
                                    <span className={`status ${(item.status.toLocaleLowerCase())}`}>{item.status}</span>
                                </div>
                            ))
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ApproveTransferPage;