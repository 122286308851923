import { Control, Controller, FieldValues, FieldPath } from 'react-hook-form';
import AppSelectInput from './base/app-select-input'; 
import { LabelValue } from '../../types/label-value.types';
import AppFormElementContainer from './base/app-form-element-container';
import AppFormElementLabel from './base/app-form-element-label';
import './app-form-select-input.scss';

interface AppFormSelectInputProps<TForm extends FieldValues, TName extends FieldPath<TForm>, TValue extends (string | number)> {
  control: Control<TForm>;
  name: TName;
  options: LabelValue<TValue>[];
  placeholder?: string;
  label?: string;
}

const AppFormSelectInput = <TForm extends FieldValues, TName extends FieldPath<TForm>, TValue extends (string | number)>({
  control,
  name,
  options,
  placeholder,
  label,
}: AppFormSelectInputProps<TForm, TName, TValue>) => {
  return (
    <AppFormElementContainer className={`app-form-select-input `}>
    {label &&
    <AppFormElementLabel
        name={name}
        label={label}
    ></AppFormElementLabel>
    }
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <AppSelectInput
            options={options}
            value={field.value}
            onChange={field.onChange}
            placeholder={placeholder}
          />
        )}
      />
    </AppFormElementContainer>
  );
};

export default AppFormSelectInput;
