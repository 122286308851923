import React, { useState, useEffect, useRef } from 'react';
import './app-select.scss';
import { LabelValue } from '../../../types/label-value.types';
import { ReactComponent as CaretIcon } from '../../../assets/icons/simple-caret.svg';

interface DropdownProps<T = string> { 
  options: LabelValue<T>[];
  value?: T;
  onChange: (value: T) => void;
  placeholder?: string;
  icon?: React.ReactNode;
}

const AppSelect = <T,>({ options, value, onChange, placeholder, icon }: DropdownProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: LabelValue<T>) => {
    onChange(option.value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);
  
  return (
    <div className="app-dropdown-container form" ref={dropdownRef}>
      <div className="dropdown-header" onClick={handleDropdownToggle}>
        <div className='icon-label-container'>
          {icon && icon}
          <span>
            {value ? options.find(option => option.value === value)?.label : placeholder}
          </span>
        </div>
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>
          <CaretIcon width={16} height={10} />
        </span>
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option) => (
            <div
              key={option.value?.toString()}
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppSelect;
