import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import './analytics-chart.scss';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface DataItem {
    value: number;        
    currencyValue: number; 
}

export interface Dataset {
    label: string;
    data: DataItem[];     
    borderColor: string;
    borderWidth?: number;
    backgroundColor: string;
    tension?: number;
    pointBorderColor?: string;
    pointBorderWidth?: number;
    pointRadius?: number;
    pointHoverRadius?: number;
    fill: boolean;
}

interface AnalyticsChartProps {
    data: {
        labels: string[];
        datasets: Dataset[]; 
    };
}

const currentPrices = {
    BTC: 50000,  
    ETH: 4000,   
    USDT: 1     
};

const AnalyticsChart: React.FC<AnalyticsChartProps> = ({ data }) => {
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem: any) {
                        const datasetLabel = tooltipItem.dataset.label || '';
                        const assetAmount = tooltipItem.raw; 
    
                        let currencyValue = 0;
                        if (datasetLabel === 'BTC') {
                            currencyValue = assetAmount * currentPrices.BTC;
                        } else if (datasetLabel === 'ETH') {
                            currencyValue = assetAmount * currentPrices.ETH;
                        } else if (datasetLabel === 'USDT') {
                            currencyValue = assetAmount * currentPrices.USDT;
                        }
    
                        return `${datasetLabel}: $${currencyValue.toFixed(2)}`;
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#FFF',
                    padding: 30,
                },
            },
            y: {
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 25,
                    color: '#fff',
                },
                beginAtZero: false,
                suggestedMax: 100,
                grid: {
                    drawBorder: false,
                    color: '#4f4f4f',
                },
            },
        },
    };

    return (
        <div className='analytics-chart-container'>
            <div className='analytics-chart'>
                <Line 
                     data={{
                        ...data,
                        datasets: data.datasets.map((dataset: Dataset) => ({
                            ...dataset,
                            data: dataset.data.map((item: DataItem) => item), // Pass DataItem objects
                        }))
                    }}  
                    options={options} 
                />
            </div>
        </div>
    );
};

export default AnalyticsChart;
