import React, { HTMLInputTypeAttribute, ReactElement, SVGProps } from 'react';
import { Message } from 'react-hook-form/dist/types';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { ValidationRule } from 'react-hook-form/dist/types/validator';
import './app-form-input.scss';
import AppFormElementLabel from './base/app-form-element-label';
import AppFormElementContainer from './base/app-form-element-container';
import AppInput from './base/app-input';
import { FieldPath } from 'react-hook-form/dist/types';

interface Validations {
  required: Message | ValidationRule<boolean>;
  min: ValidationRule<number | string>;
  max: ValidationRule<number | string>;
  maxLength: ValidationRule<number>;
  minLength: ValidationRule<number>;
}

interface AppFormInputProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  theme?: 'auth';
  control: Control<TForm>;
  type?: HTMLInputTypeAttribute;
  name: TName;
  label?: string;
  validations?: Partial<Validations>;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: React.ReactElement<SVGProps<SVGSVGElement>>;
}

const AppFormInput = <TForm extends FieldValues, TName extends FieldPath<TForm>>(
  {theme,  control, name, type = 'text', label, validations, placeholder, onChange, icon}: AppFormInputProps<TForm, TName>
) => {
  return (
    <AppFormElementContainer className={`app-form-input ${theme ? theme : ''} `}>
      {label && <AppFormElementLabel
        className={theme}
        name={name}
        label={label}
        validations={validations}
      ></AppFormElementLabel>
      }
      <Controller
        name={name}
        control={control}
        rules={validations}
        render={({field, fieldState}) => {
          return (
          <>
            <AppInput
              theme={theme}
              id={name}
              type={type}
              placeholder={placeholder ? placeholder : label}
              value={field.value}
              onBlur={field.onBlur}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) onChange(e);
              }}
              icon={icon}
            />
            
           {fieldState.error?.type && <p className="form-element-error">{fieldState.error?.type}</p> }
          </>
        )}}
      />
    </AppFormElementContainer>
  ) as ReactElement;
}

export default AppFormInput;
