import React, { HTMLInputTypeAttribute, SVGProps, useRef } from 'react';
import './app-input.scss';
import { ForwardedProps } from '../../../types/component-props.types';

interface AppFormInputProps extends ForwardedProps {
  theme?: 'auth',
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  onBlur?: () => void;
  onChange?: (e?: any) => void;
  value?: any;
  readOnly?: boolean;
  icon?: React.ReactElement<SVGProps<SVGSVGElement>>;
}

const AppInput: React.FC<AppFormInputProps> = (
  {theme, type = 'text', value, placeholder, onBlur, onChange, id, readOnly, icon }
) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  
  const focusInput = () => {
    inputRef.current?.focus();
  }

  return (
    <div className={`app-input form-field-container ${theme ? theme : ''} ${icon ? 'hasIcon' : ''}`} onClick={focusInput}>
      {icon && icon}
      <input
        ref={inputRef}
        id={id}
        type={type}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
      />
    </div>
  );
  
}

export default AppInput;
